<template>
  <div>
    <p class="has-text-grey">Vehicle overview</p>
    <div>
      <div class="columns is-multiline">
        <div class="column">
          <span class="title is-4">{{ description }} </span>
        </div>
        <div class="column is-narrow" v-if="$experian">
          <a class="has-text-info help" @click="openContactForm">
            <span class="icon is-small">
              <i class="fal fa-question-circle" />
            </span>
            <span>Enquire about this data</span>
          </a>
        </div>
      </div>
    </div>
    <Grid :data="grid" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AutoCheckSummary',
  components: {
    Grid: () => import('../components/AutoCheckGrid')
  },
  computed: {
    ...mapGetters('autocheck', ['description', 'vrm', 'vin', 'grid']),
    formData() {
      const { description, vrm, vin } = this
      return { vrm, vin, description, product: 'AutoCheck' }
    }
  },
  methods: {
    openContactForm() {
      this.$mxp.track(`autocheck_description_open_contact_form`)
      this.$modal.open('contact/Contact', this.formData)
    }
  }
}
</script>
